// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "css/styles"
import 'bootstrap';
import '../stylesheets/application';
import "@fortawesome/fontawesome-free/js/all";
import 'pikaday/plugins/pikaday.jquery';
import { Dropzone } from "dropzone";

// document.addEventListener("turbolinks:load", () => {
//   $('[data-toggle="tooltip"]').tooltip()
// });

require("jquery")
require("jquery-ui")
require('admin-lte');
require('pikaday');
require("dropzone");
Rails.start()
Turbolinks.start()
ActiveStorage.start()
global.Rails = Rails;
global.Dropzone = Dropzone;